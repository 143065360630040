import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";

const App = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

  // Get email and uid from the URL's query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email") || "";
  const uid = queryParams.get("uid") || "";

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const audioChunks: Blob[] = [];

      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        setRecordedAudio(audioBlob); // Set the recorded audio
        sendAudioToAPI(audioBlob); // Send the audio to the API
      };

      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const playAudio = () => {
    if (recordedAudio) {
      const audioUrl = URL.createObjectURL(recordedAudio);
      const audio = new Audio(audioUrl);
      audio.play();
    }
  };

  const sendAudioToAPI = async (audioBlob: Blob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "recording.wav");
    formData.append("email", email);
    formData.append("uid", uid);

    console.log(formData, "form data");

    try {
      const response = await fetch("https://retell.backend.docsightai.com/process-audio-file/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Audio file uploaded successfully:", result);
    } catch (error) {
      console.error("Failed to upload audio:", error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={toggleRecording}>
          {isRecording ? "Stop" : "Start"}
        </button>
        <div>
          <h3>Recorded Audio:</h3>
          {recordedAudio && (
            <div>
              <button onClick={playAudio}>Play Audio</button>
              <p>Audio recorded successfully!</p>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default App;
